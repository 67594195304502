import vflogo from "../images/vfLogo.png";

export const VibeFitness = () => {
  return (
    <div style={{ backgroundColor: "#2A2E30", height: "90vh" }}>
      <div className="main">
        <img
          src={vflogo}
          className="d-inline-block align-top"
          alt="tech for good logo"
        />
      </div>
    </div>
  );
};
